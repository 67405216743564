import { ResponsiveBar } from "@nivo/bar";
import { React, useState } from "react";
import { Box, Typography, FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { getModernizationCostGraphData } from "../../services/transformResultData";

const BarChart = ({ isPortfolioAnalysis = true, data, keys, label, specific }) => {
    const chartTheme = {
        // background: "#ffffff",
        textColor: "#333333",
        fontSize: 13,
        axis: {
            domain: {
                line: {
                    stroke: "#777777",
                    strokeWidth: 2,
                },
            },
            legend: {
                text: {
                    fontSize: 16,
                    fill: "#000000",
                },
            },
            ticks: {
                line: {
                    stroke: "#777777",
                    strokeWidth: 2,
                },
                text: {
                    fontSize: 13,
                    fill: "#333333",
                },
            },
        },
        grid: {
            line: {
                stroke: "#dddddd",
                strokeWidth: 1,
            },
        },
        legends: {
            title: {
                text: {
                    fontSize: 11,
                    fill: "#333333",
                },
            },
            text: {
                fontSize: 13,
                fill: "#333333",
            },
            ticks: {
                line: {},
                text: {
                    fontSize: 10,
                    fill: "#333333",
                },
            },
        },
        annotations: {
            text: {
                fontSize: 13,
                fill: "#333333",
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1,
            },
            link: {
                stroke: "#000000",
                strokeWidth: 1,
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1,
            },
            outline: {
                stroke: "#000000",
                strokeWidth: 2,
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1,
            },
            symbol: {
                fill: "#000000",
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1,
            },
        },
        tooltip: {
            container: {
                background: "#ffffff",
                color: "#333333",
                fontSize: 12,
            },
            basic: {},
            chip: {},
            table: {},
            tableCell: {},
            tableCellValue: {},
        },
    };

    return (
        <ResponsiveBar
            data={data}
            groupMode="grouped"
            indexBy="year"
            keys={keys}
            margin={{ top: 50, right: 210, bottom: 50, left: 80 }}
            padding={0.5}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={["#a6dbf7", "#FF8E3B"]}
            borderWidth={1}
            borderRadius={4}
            borderColor={{
                from: "color",
                modifiers: [["darker", 1]],
            }}
            // // colorBy="indexValue"
            defs={[
                {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "#5CB8FF",
                    rotation: -45,
                    lineWidth: 4,
                    spacing: 12,
                },
            ]}
            fill={[
                {
                    match: {
                        id: "Geschätzte Modernisierngskosten",
                    },
                    id: "lines",
                },
            ]}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                // renderTick: CustomTickX,
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Jahr",
                legendPosition: "middle",
                legendOffset: 32,
            }}
            axisLeft={{
                // renderTick: CustomTickY,
                tickValues: 5,
                tickSize: 3,
                tickPadding: 5,
                tickRotation: 30,
                legend: label,
                legendPosition: "middle",
                legendOffset: -60,
            }}
            enableLabel={false}
            legends={[
                {
                    dataFrom: "keys",
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 30,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: "hover",
                            style: {
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
            motionConfig="stiff"
            role="application"
            theme={chartTheme}
            enableGridY
            markers={[
                {
                    axis: "y",
                    value: 0,
                    lineStyle: {
                        stroke: "rgba(0, 0, 0, .6)",
                        strokeWidth: 2,
                    },
                },
            ]}
        />
    );
};

const YearlyModernizationCostsBarChart = ({ yearlyModernizationCosts, heightGraphs, singleBuilding = false }) => {
    const [modernisationGraphDisplayType, setModernisationGraphDisplayType] = useState("absolut");

    const modernisationRadioGroupHandleChange = (event) => {
        setModernisationGraphDisplayType(event.target.value);
    };

    return (
        <>
            <Box height={String(heightGraphs * 250) + "px"} width="100%" mt="10px">
                <Typography variant="h3" sx={{ display: "flex", justifyContent: "center", marginLeft: "-7%", mb: -5 }}>
                    {"Modernisierungskosten"}

                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="modernisation-radio-buttons-group-label"
                            name="modernisation-row-radio-buttons-group"
                            onChange={modernisationRadioGroupHandleChange}
                            sx={{ mt: -0.5, ml: 3 }}
                        >
                            <FormControlLabel
                                value="absolut"
                                control={<Radio />}
                                checked={modernisationGraphDisplayType === "absolut"}
                                label="absolut"
                            />
                            <FormControlLabel value="spezifisch" control={<Radio />} label="spezifisch" />
                        </RadioGroup>
                    </FormControl>
                </Typography>

                <BarChart
                    isPortfolioAnalysis={true}
                    data={
                        modernisationGraphDisplayType === "absolut"
                            ? getModernizationCostGraphData("absolute", yearlyModernizationCosts)
                            : getModernizationCostGraphData("specific", yearlyModernizationCosts)
                    }
                    keys={["Geschätzte Kosten", "Angegebene Kosten"]}
                    label={modernisationGraphDisplayType === "absolut" ? "€/Jahr" : "€/(m\u00B2\u2217a)"}
                    specific={modernisationGraphDisplayType !== "absolut"}
                />
            </Box>
        </>
    );
};

export default YearlyModernizationCostsBarChart;
