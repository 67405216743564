import {
    Typography,
    Stack,
    Paper,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    TextField,
    Divider,
    Box,
    CircularProgress,
    Alert,
    Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState, useEffect, useCallback } from "react";
import ResultBarGauges from "../../components/ResultBarGauges";
import ConsumptionResultBarGraph from "../../components/ConsumptionResultBarGraph";
import EmissionResultBarGraph from "../../components/EmissionResultBarGraph";
import YearlyModernizationCostsBarChart from "../../components/ModernizationCosts/YearlyModernizationCostsBarChart";
import ModernizationCostsTable from "../../components/ModernizationCosts/ModernizationCostsTable";
import fetchUserResults from "../../services/transformResultData";

const NumberInput = styled(TextField)`
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        display: none;
    }
`;

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const LoadingSpinner = (
    <div
        style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.4)",
            backdropFilter: "blur(3px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
        }}
    >
        <CircularProgress />
    </div>
);

/**
 * PortfolioAnalysis component for analyzing and visualizing building performance data.
 *
 * This component allows users to select a subset of buildings from their portfolio and view aggregated performance metrics.
 * It provides options to filter buildings based on specific criteria and displays results using various graphical representations.
 *
 * States:
 * - tempBuildingSelectionAmount: Temporary state for debounced input handling.
 * - buildingSelectionAmount: Final state reflecting the number of buildings to analyze.
 * - buildingFilterType: Type of filter applied to building selection (e.g., 'all', 'worst', 'best').
 * - userResults: Stores the results of the building performance analysis.
 * - loading: Indicates whether the data is currently being loaded.
 * - reLoading: Indicates a reload of data based on new filter criteria.
 * - energyEfficiencyClasses: Stores energy efficiency classification data.
 * - co2EmissionClasses: Stores CO2 emission classification data.
 *
 * The component fetches and displays data based on the selected filter criteria and provides visual feedback during data loading.
 *
 * Returns:
 *   ReactElement: The rendered component with building selection controls and result visualizations.
 */
const PortfolioAnalysis = () => {
    // -- STATES -- //
    const [tempBuildingSelectionAmount, setTempBuildingSelectionAmount] = useState(10);
    const [buildingSelectionAmount, setBuildingSelectionAmount] = useState(10);

    const [buildingFilterType, setBuildingFilterType] = useState("all");

    const [userResults, setUserResults] = useState(false);
    const [loading, setLoading] = useState(true);
    const [reLoading, setReLoading] = useState(false);
    const [energyEfficiencyClasses, setEnergyEfficiencyClasses] = useState();
    const [co2EmissionClasses, setCo2EmissionClasses] = useState();
    const [modernizationCosts, setModernizationCosts] = useState();

    // fetch data from backend
    useEffect(() => {
        setReLoading(true);
        (async () => {
            fetchUserResults({
                filter_type: buildingFilterType,
                filter_choice: [buildingSelectionAmount],
            }).then((response) => {
                if (response.userResults.length > 0) {
                    setUserResults(response.userResults);
                }
                setLoading(false);
                setEnergyEfficiencyClasses(response.energyEfficiencyClasses);
                setCo2EmissionClasses(response.co2EmissionClasses);
                setModernizationCosts(response.modernizationCosts);
                setReLoading(false);
            });
        })();
    }, [buildingFilterType, buildingSelectionAmount]);

    // handle RadioButton selection
    const buildingFilterRadioGroupHandleChange = (event) => {
        setBuildingFilterType(event.target.value);
    };

    // debounce for the buildingSelectionAmount TextField
    useEffect(
        () => {
            // Wait 1000ms before copying the value of tempBuildingSelectionAmount into buildingSelectionAmount;
            const timeout = setTimeout(() => {
                setBuildingSelectionAmount(tempBuildingSelectionAmount);
            }, 1000);

            // If the hook is called again, cancel the previous timeout
            // This creates a debounce instead of a delay
            return () => clearTimeout(timeout);
        },
        // Run the hook every time the user makes a keystroke
        [tempBuildingSelectionAmount]
    );

    const getDefaultResultYear = useCallback(() => {
        if (!userResults) {
            return [];
        }
        var res = null;
        userResults.toReversed().every((result) => {
            // choose a year with real data, not forecast data
            if (result.total_living_space > 0) {
                res = [result];
                return false;
            }
            return true;
        });
        return res;
    }, [userResults]);

    return (
        <>
            <Stack width="160ch" spacing={3} mb={3} data-testid="building-selection-stack">
                <Item sx={{ mt: "30px", textAlign: "left" }}>
                    <Typography sx={{ ml: 1, flex: "1 1 100%" }} variant="h3">
                        Gebäudeauswahl
                    </Typography>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="building-selection-radio-buttons-group-label"
                            name="building-selection-row-radio-buttons-group"
                            onChange={buildingFilterRadioGroupHandleChange}
                            sx={{ mt: 0, ml: 3 }}
                            data-testid="building-selection-radio-group"
                        >
                            <FormControlLabel
                                value="all"
                                control={<Radio />}
                                label="alle"
                                checked={buildingFilterType === "all"}
                                data-testid="building-selection-radio-all"
                            />
                            <Divider orientation="vertical" flexItem />
                            <FormControlLabel
                                sx={{ ml: 1.5 }}
                                value="worst"
                                control={<Radio />}
                                label="schlechteste"
                                data-testid="building-selection-radio-worst"
                            />
                            <FormControlLabel
                                value="best"
                                control={<Radio />}
                                label="beste"
                                data-testid="building-selection-radio-best"
                            />
                            <NumberInput
                                size="small"
                                sx={{ width: "50px" }}
                                value={tempBuildingSelectionAmount}
                                type="number"
                                onChange={(e) => {
                                    setTempBuildingSelectionAmount(parseInt(e.target.value));
                                }}
                                data-testid="building-selection-number-input"
                            ></NumberInput>
                            <Typography mt={1.1} ml={1}>
                                Gebäude bezogen auf spezifischen Endenergieverbrauch
                            </Typography>
                        </RadioGroup>
                    </FormControl>
                </Item>
            </Stack>
            {!loading ? (
                userResults ? (
                    <Stack width="160ch" spacing={3} mb={3} data-testid="results-stack">
                        <>
                            <Item>
                                {reLoading && LoadingSpinner}
                                {getDefaultResultYear() && (
                                    <ResultBarGauges
                                        co2EmissionClasses={co2EmissionClasses}
                                        energyEfficiencyClasses={energyEfficiencyClasses}
                                        heightScales={0.9}
                                        userResults={getDefaultResultYear()}
                                        data-testid="result-bar-gauges"
                                    />
                                )}
                                <Grid
                                    container
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        ml: -5,
                                    }}
                                >
                                    <Box maxHeight="5ch" width="20ch">
                                        <svg>
                                            <g transform={`translate(10, 20)`}>
                                                <path
                                                    transform={`translate(0,5.1)`}
                                                    d="M-10 0 L 10 0 L 0 -10 L -10 0 Z"
                                                    fill={"#2D2D2D"}
                                                />
                                                <text
                                                    transform={`translate(12, -3)`}
                                                    fontFamily="Arial"
                                                    fontSize="16"
                                                    fill={"#000000"}
                                                >
                                                    : Verbrauchsangaben
                                                </text>
                                            </g>
                                        </svg>
                                    </Box>
                                    <Box maxHeight="5ch" width="20ch">
                                        <svg>
                                            <g transform={`translate(50, 20)`}>
                                                <path
                                                    transform={`translate(0,-4)`}
                                                    d={`
                                                                M 0 -10
                                                                A 5.5 5.5 0 1 1 -0.01 -10
                                                                M -12 -4
                                                                L -8 -4
                                                                M 12 -4
                                                                L 8 -4
                                                                M 0 -17
                                                                L 0 -13
                                                                M 0 4
                                                                L 0 8
                                                                M -10.1 -12.1
                                                                L -7 -9
                                                                M 10.1 -12.1
                                                                L 7 -9
                                                                M -8.1 5.1
                                                                L -5 2
                                                                M 8.1 5.1
                                                                L 5 2
                                                            `}
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    stroke={"#fcca03"}
                                                    fill={"#fcca03"}
                                                />
                                                <text
                                                    transform={`translate(15, -3)`}
                                                    fontFamily="Arial"
                                                    fontSize="16"
                                                    fill={"#000000"}
                                                >
                                                    : Photovoltaik-Einsparung
                                                </text>
                                            </g>
                                        </svg>
                                    </Box>
                                </Grid>
                            </Item>
                            <Item>
                                <ConsumptionResultBarGraph
                                    userResults={userResults}
                                    heightGraphs={1.1}
                                    bigAbsoluteUnits={true}
                                    data-testid="consumption-result-bar-graph"
                                    singleBuilding={false}
                                />
                            </Item>
                            <Item>
                                <EmissionResultBarGraph
                                    userResults={userResults}
                                    heightGraphs={1.1}
                                    bigAbsoluteUnits={true}
                                    data-testid="emission-result-bar-graph"
                                    singleBuilding={false}
                                />
                            </Item>
                            <Item>
                                <YearlyModernizationCostsBarChart
                                    // TODO
                                    yearlyModernizationCosts={modernizationCosts}
                                    heightGraphs={1.1}
                                    singleBuilding={false}
                                />
                            </Item>
                            <Item>
                                <ModernizationCostsTable data={modernizationCosts} />
                            </Item>
                        </>
                    </Stack>
                ) : (
                    <Box
                        width="100%"
                        height="100%"
                        sx={{
                            display: "flex",
                            marginLeft: "5ch",
                            marginTop: "5ch",
                            marginBottom: "5ch",
                        }}
                        data-testid="no-results-box"
                    >
                        <Alert severity="info" sx={{ textAlign: "left", height: "12ch", fontSize: 14 }}>
                            <strong>Es liegen noch keine Ergebnisse vor.</strong>
                            <br />
                            Fügen Sie unter "Gebäudedetails" ein erstes Gebäude
                            <br />
                            inklusive Verbrauchsdaten und Gebäudezustand
                            <br />
                            hinzu, um Ergebnisse zu sehen.
                            <br />
                        </Alert>
                    </Box>
                )
            ) : (
                <Box
                    width="100%"
                    height="100%"
                    sx={{
                        display: "flex",
                        marginLeft: "10%",
                        marginTop: "10%",
                        marginBottom: "10%",
                        marginRight: "10%",
                    }}
                    data-testid="loading-box"
                >
                    <CircularProgress size={150} color="secondary" data-testid="loading-spinner" />
                </Box>
            )}
        </>
    );
};

export default PortfolioAnalysis;
