// Scale.js
// Author: Lukas Aldenhoff 2023, lukas.aldenhoff@gmail.com

import { Box, Grid } from "@mui/material";
import { ResponsiveBullet } from "@nivo/bullet";
import DescriptionIcon from "@mui/icons-material/Description";

const _marginLeft = 130;
const _marginTop = 20;
const _marginRight = 30;
const _marginBottom = 50;

const chartTheme = {
    // background: "#ffffff",
    textColor: "#333333",
    fontSize: 13,
    axis: {
        domain: {
            line: {
                stroke: "#777777",
                strokeWidth: 1,
            },
        },
        legend: {
            text: {
                fontSize: 16,
                fill: "#000000",
            },
        },
        ticks: {
            line: {
                stroke: "#777777",
                strokeWidth: 1,
            },
            text: {
                fontSize: 13,
                fill: "#333333",
            },
        },
    },
    grid: {
        line: {
            stroke: "#dddddd",
            strokeWidth: 1,
        },
    },
    legends: {
        title: {
            text: {
                fontSize: 11,
                fill: "#333333",
            },
        },
        text: {
            fontSize: 13,
            fill: "#333333",
        },
        ticks: {
            line: {},
            text: {
                fontSize: 10,
                fill: "#333333",
            },
        },
    },
    annotations: {
        text: {
            fontSize: 13,
            fill: "#333333",
            outlineWidth: 2,
            outlineColor: "#ffffff",
            outlineOpacity: 1,
        },
        link: {
            stroke: "#000000",
            strokeWidth: 1,
            outlineWidth: 2,
            outlineColor: "#ffffff",
            outlineOpacity: 1,
        },
        outline: {
            stroke: "#000000",
            strokeWidth: 2,
            outlineWidth: 2,
            outlineColor: "#ffffff",
            outlineOpacity: 1,
        },
        symbol: {
            fill: "#000000",
            outlineWidth: 2,
            outlineColor: "#ffffff",
            outlineOpacity: 1,
        },
    },
    // currently we dont want the tooltip
    // tooltip: {
    //     container: {
    //         background: "#ffffff",
    //         color: "#333333",
    //         fontSize: 12,
    //     },
    //     basic: {},
    //     chip: {},
    //     table: {},
    //     tableCell: {},
    //     tableCellValue: {},
    // },
};

const CustomRange = ({ x, y, width, height, color, onMouseEnter, onMouseMove, onMouseLeave }) => (
    <rect
        x={x + 1}
        y={y - 3}
        // rx={5}
        // ry={5}
        width={width - 2}
        height={height + 2}
        fill={color}
        // onMouseEnter={onMouseEnter}
        // onMouseMove={onMouseMove}
        // onMouseLeave={onMouseLeave}
    />
);

const CustomMarker = ({ x, size, color, onMouseEnter, onMouseMove, onMouseLeave }) =>
    color === "#2D2D2D" ? ( // normal black triangle marker
        <g
            transform={`translate(${x},-3)`}
            // onMouseEnter={onMouseEnter}
            // onMouseMove={onMouseMove}
            // onMouseLeave={onMouseLeave}
        >
            <line x1={0} x2={0} y1={0} y2={size} stroke={color} strokeWidth={1.5} strokeDasharray="2,2" fill="none" />
            {/* <path d="M0 -10 L 10 0 L 0 10 L -10 0 Z" fill={color} /> */}
            <path transform={`translate(0,${size + 9})`} d="M-10 0 L 10 0 L 0 -10 L -10 0 Z" fill={color} />
        </g>
    ) : color === "#fcca03" ? ( // yellow 'sun' marker for pv
        <g transform={`translate(${x},-3)`}>
            <line x1={0} x2={0} y1={0} y2={size} stroke={color} strokeWidth={2} strokeDasharray="3,3" fill="none" />
            <path
                transform={`translate(0,${size + 7})`}
                d={`
                    M 0 -10
                    A 5.5 5.5 0 1 1 -0.01 -10
                    M -12 -4
                    L -8 -4
                    M 12 -4
                    L 8 -4
                    M 0 -17
                    L 0 -13
                    M 0 4
                    L 0 8
                    M -10.1 -12.1
                    L -7 -9
                    M 10.1 -12.1
                    L 7 -9
                    M -8.1 5.1
                    L -5 2
                    M 8.1 5.1
                    L 5 2
                `}
                strokeWidth="2"
                strokeLinecap="round"
                stroke={color}
                fill={color}
            />
            {/* <text
                transform={`translate(-12.37,${size + 15})`}
                fontFamily="Arial"
                fontSize="18"
                fill={color}
                fontWeight={"bold"}
            >
                PV
            </text> */}
        </g>
    ) : color === "#000000" ? ( // black marker for energy certificate
        <g transform={`translate(${x}, -3)`}>
            <line x1={0} x2={0} y1={0} y2={size} stroke={color} strokeWidth={1} strokeDasharray="1.5,2" fill="none" />
            <text
                transform={`translate(-8.37,${size + 17})`}
                fontFamily="Arial"
                fontSize="24"
                fill={color}
                fontWeight={"bold"}
            >
                A
            </text>
        </g>
    ) : color == "#aa0000" ? (
        <g
            transform={`translate(${x},-3)`}
            // onMouseEnter={onMouseEnter}
            // onMouseMove={onMouseMove}
            // onMouseLeave={onMouseLeave}
        >
            <line x1={0} x2={0} y1={0} y2={size} stroke={color} strokeWidth={1.5} strokeDasharray="2,2" fill="none" />
            {/* <path d="M0 -10 L 10 0 L 0 10 L -10 0 Z" fill={color} /> */}
            <path transform={`translate(0,${size + 9})`} d="M-8 0 L 8 0 L 0 -8 L -8 0 Z" fill={color} />
        </g>
    ) : (
        <></>
    );

const Scale = ({ data, marginLeft = _marginLeft, marginRight = _marginRight }) => {
    return (
        <>
            <Box
                sx={{
                    ml: String(marginLeft) + "px",
                    mb: "-20px",
                    mr: String(marginRight) + "px",
                }}
                height="20px"
            >
                <Grid container>
                    {data.labels.map((val, index) => {
                        return (
                            <Grid
                                key={"scale-label-grid-" + index}
                                item
                                sx={{
                                    backgroundColor: "#DEDEDE",
                                    mb: "-15px",
                                    fontWeight: "bold",
                                    borderRight: 1,
                                    borderLeft: 1,
                                    borderColor: "#FFFFFF",
                                }}
                                textAlign="center"
                                width={
                                    String(
                                        ((data.ranges[index + 1] - data.ranges[index]) /
                                            (data.ranges[data.ranges.length - 1] - data.ranges[0])) *
                                            100
                                    ) + "%"
                                }
                            >
                                {val}
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
            <ResponsiveBullet
                data={[data]}
                margin={{ top: _marginTop, right: marginRight, bottom: _marginBottom, left: marginLeft }}
                spacing={46}
                titleAlign="start"
                titleOffsetX={-120}
                titleOffsetY={0}
                measureSize={0}
                marker
                markerSize={1.1}
                markerColors={data.markerColors} 
                rangeColors={[
                    "#49D60B",
                    "#88E03E",
                    "#C7FE48",
                    "#E8FE48",
                    "#F0FE48",
                    "#FEF548",
                    "#FEBE48",
                    "#FE9048",
                    "#F85537",
                    "#E63819",
                ]}
                theme={chartTheme}
                rangeComponent={CustomRange}
                markerComponent={CustomMarker}
            />
        </>
    );
};

export default Scale;
